

.css-7m51b8-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 7.5px 4px 7.5px 5px;
    font-family: 'Kanit';
    font-size: 16px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-family: 'Kanit' !important;
}

.css-7m51b8-MuiAutocomplete-root {
    font-family: 'Kanit' !important;
}





