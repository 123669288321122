.card {
  background-color: #f1f2f4;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-family: 'Kanit', sans-serif;
}

.card-body {
  background-color: #ffffff;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.1rem;
  width: 10rem;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  width: 60px;
  height: 60px;
}

.card-img-top-t {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  width: 20px;
  height: 20px;
}

.list-group-flush {
  width: 10rem;
}




.card-tmd {
  background-color: #ffffff;
  width: 15rem;
}

.card-map {
  width: 100rem;
  height: 50rem;
}

.b2 {
  background-color: #ffffff;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
  font-family: 'Kanit', sans-serif;
  height: 700px;
}

.b3 {
  background-color: #ffffff;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
  font-family: 'Kanit', sans-serif;
  height: 700px;
}



.b4 {
  flex: 1 1 auto;
}

.b5 {
  background-color: #ffffff;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
  font-family: 'Kanit', sans-serif;
  height: 500px;
}

.b6 {
  background-color: #f1f2f4;
  margin-top: 20px;
  /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
  font-family: 'Kanit', sans-serif;
  height: auto;
  width: 100%;

}



#map-sphere .mapbox-gl-draw_ctrl-draw-btn {
  background-size: 22px;
  background-color: #ffa939;
  border: 1px solid #f3b96e;
}

#map-sphere .mapbox-gl-draw_ctrl-draw-btn:hover {
  background-size: 24px;
  background-color: #ec9421;
}

#map-sphere .mapbox-gl-draw_ctrl-draw-btn:first-child {
  border-radius: 3px 3px 0px 0px;
}

#map-sphere .mapbox-gl-draw_ctrl-draw-btn:last-child {
  border-radius: 0px 0px 3px 3px;
}

#map-sphere .mapbox-gl-draw_line {
  background-image: url('https://www.svgrepo.com/show/309501/data-line.svg');
}

#map-sphere .mapbox-gl-draw_polygon {
  background-image: url('https://www.svgrepo.com/show/399227/polygon-pt.svg');
}

#map-sphere .mapbox-gl-draw_point {
  background-image: url('https://www.svgrepo.com/show/399217/point.svg');
}

#map-sphere .mapbox-gl-draw_trash {
  background-image: url('https://www.svgrepo.com/show/379248/remove.svg');
}

.map-container {
  position: absolute;
  height: 89.5vh;
  width: 100%;
  margin-top: -3vh;
}


.leaflet-layerstree-header-pointer {
  font-family: 'Kanit';
  font-size: 14.5px;
  font-weight: bold;
}

.leaflet-layerstree-header-name {
  font-family: 'kanit';
}




.layer-tree-control {
  background-color: white;
  border: solid 1px gray;
  max-height: calc(100vh - 55px);
  overflow-y: auto;
  min-width: 250px;
  padding: 8px;
}

.layer-tree-control .tree-icon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
  margin-left: 2px;
  margin-top: 2px;
  background-size: contain;
}

.layer-tree-control .tree-icon.plus {
  background-image: url("https://raw.githubusercontent.com/ignaciofagian/L.LayerTreeControl/17965eaae37f52686300f38a26cd737912515155/src/images/minus.svg");
}

.layer-tree-control .tree-icon.minus {
  background-image: url("https://raw.githubusercontent.com/ignaciofagian/L.LayerTreeControl/17965eaae37f52686300f38a26cd737912515155/src/images/plus.svg");
}

.layer-tree-control .node-header {
  margin: 3px 0;
  display: flex;
  padding: 1px 6px;
}

.layer-tree-control .leaf-header label span:hover {
  font-weight: 500;
}

.layer-tree-control .leaf-header {
  margin: 3px 0;
  display: flex;
  padding: 1px 6px;
}

.layer-tree-control .leaf-header:hover,
.layer-tree-control .node-header:hover {
  background-color: rgba(230, 229, 229, 0.662);
}

.layer-tree-control .tree-label {
  line-height: 20px;
  font-size: 13px;
}

.layer-tree-control .legend {
  display: flex;
  align-items: center;
}

.layer-tree-control .legend-img {
  margin-right: 4px;
  max-height: 25px;
  max-width: 25px;
}

.layer-tree-control .legend-list {
  margin-left: 18px;
}

.layer-tree-control .legend-list .legend {
  margin-bottom: 4px;
}

.layer-tree-control .tree-legend-sm {
  margin-top: -2.5px;
}

.layer-tree-control .tree-legend-sm .legend-img {
  max-height: 20px;
  max-width: 20px;
}

/* custom checkbox */
.layer-tree-control .tree-check {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 1.5px 0px;
}

.layer-tree-control .tree-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin-left: -12px;
}

.layer-tree-control .tree-check:hover input~.check-mark {
  background-color: rgb(243, 243, 243);
}

.layer-tree-control .tree-check input:checked~.check-mark {
  background-color: #7b7b7bf0;
}

.layer-tree-control .check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fafafa;
  border: solid 1px gray;
  border-radius: 2px;
}

.layer-tree-control .check-mark:after {
  content: "";
  position: absolute;
  display: none;
}

.layer-tree-control .tree-check input:checked~.check-mark:after {
  display: block;
}

.layer-tree-control .tree-check .check-mark:after {
  left: 4.5px;
  top: 1.3px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* utility classes */
.layer-tree-control .hidden {
  display: none;
}

.layer-tree-control .space {
  width: 23px;
  display: block;
}

.leaflet-layerstree-opened {
  /* background:black; */
  
  /* background-image: url("http://localhost:3001/color-dam5.png"); */
}


.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  margin: 2px;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  transform: scale(1.25);
}
.leaflet-layerstree-header-name {
  font-family: 'kanit';
  margin: 5px;
}

.leaflet-layerstree-header-space {
  margin: -1px;
}
