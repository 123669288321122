.card {
    background-color: #f1f2f4;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    font-family: 'Kanit', sans-serif;
}

.card-body {
    background-color: #ffffff;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.1rem;
    width: 10rem;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    width: 60px;
    height: 60px;
}

.card-img-top-t {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    width: 20px;
    height: 20px;
}

.list-group-flush {
    width: 10rem;
}




.card-tmd {
    background-color: #ffffff;
    width: 15rem;
}

.card-map {
    width: 100rem;
    height: 50rem;
}

.b2 {
    background-color: #FFDE59;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
    font-family: 'Kanit', sans-serif;
    height: 700px;
}

.b3 {
    background-color: #ffffff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
    font-family: 'Kanit', sans-serif;
    height: 700px;
}



.b4 {
    flex: 1 1 auto;
}

.b5 {
    background-color: #ffffff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
    font-family: 'Kanit', sans-serif;
    height: 500px;
}

.b6 {
    background-color: #f1f2f4;
    margin-top: 20px;
    /* border-radius: 20px;
    box-shadow: 0px 2px 2px #ffffff; */
    font-family: 'Kanit', sans-serif;
    height: auto;
    width: 100%;

}

.tooltipHeader {
    float: right;
    font-size: 1rem;
    font-family: 'Kanit', sans-serif;
}

.tooltipDes {
    float: right;
    font-size: 0.9rem;
    font-family: 'Kanit', sans-serif;
}

.tooltipHeader::before {
    float: left;
    content: "จังหวัด:";
    padding-right: 5px;
    opacity: 0.5;
    display: block;
    font-family: 'Kanit', sans-serif;
}

.tooltipHeader::after {
    float: none;
}

.tooltipPointWrapper {
    display: block;
    text-align: center;
    padding: 10px 0;
}

.tooltipPoint {
    font-size: 1.5rem;
    padding-left: 5px;
    font-family: 'Kanit', sans-serif;
    /* color: #FF0045; */
}

.tooltipValueSuffix {
    padding-left: 5px;
    color: #1bc9a8;
    font-size: 1rem;
    font-family: 'Kanit', sans-serif;
}


.tooltipLine {
    display: block;
    opacity: 0.5;
    background-color: #fff;
    width: 100%;
    height: 1px;
    padding: 0;
    margin: 0;
}

.map-container2 {
    /* position: absolute; */
    height: 1200px;
    width: 100%;
    /* margin-top: -3vh; */
}

.b7 {
    background-color: #ffffff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    font-family: 'Kanit', sans-serif;
    height: 100vh;
}
.b8 {
    background-color: #ffffff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    font-family: 'Kanit', sans-serif;
    height: 100vh;
}

.b9 {
    background-color: #ffffff;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 10px;
    font-family: 'Kanit', sans-serif;
    height: 100px;
}

.highcharts-axis-labels {
    font-size: 16px !important
}