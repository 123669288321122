#map-sphere .mapbox-gl-draw_ctrl-draw-btn {
  background-size: 22px;
  background-color: #ffa939;
  border: 1px solid #f3b96e;
}

#map-sphere .mapbox-gl-draw_ctrl-draw-btn:hover {
  background-size: 24px;
  background-color: #ec9421;
}

#map-sphere .mapbox-gl-draw_ctrl-draw-btn:first-child {
  border-radius: 3px 3px 0px 0px;
}

#map-sphere .mapbox-gl-draw_ctrl-draw-btn:last-child {
  border-radius: 0px 0px 3px 3px;
}

#map-sphere .mapbox-gl-draw_line {
  background-image: url('https://www.svgrepo.com/show/309501/data-line.svg');
}

#map-sphere .mapbox-gl-draw_polygon {
  background-image: url('https://www.svgrepo.com/show/399227/polygon-pt.svg');
}

#map-sphere .mapbox-gl-draw_point {
  background-image: url('https://www.svgrepo.com/show/399217/point.svg');
}

#map-sphere .mapbox-gl-draw_trash {
  background-image: url('https://www.svgrepo.com/show/379248/remove.svg');
}

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 0.01px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: 'kanit';
  font-size: 12.5px;
}

.highcharts-title {
  font-weight : 100 !important
}


/* .input-wrapper {
  width: 100%;
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  background-color: white;
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 5px;
}

input:focus {
  outline: none;
}

#search-icon {
  color: royalblue;
}

.search-result {
  padding: 10px 20px;
}

.search-result:hover {
  background-color: #efefef;
}

.results-list {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: auto;
}


.search-bar-container {
  padding-top: 20vh;
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
} */